<template>
  <div class="pass-change">
    <div class="form-wrapper">
      <p class="page-title">
        Password Change
      </p>
      <a-form-model ref="passChange"
                    :model="passChange"
                    :rules="rules"
                    v-bind="layout"
      >
        <a-form-model-item has-feedback label="Email" prop="email">
          <a-input
            v-model="passChange.email"
            disabled
            type="email"
            autocomplete="off"
          />
        </a-form-model-item>
        <a-form-model-item has-feedback label="Old Password" prop="oldPass">
          <a-input v-model="passChange.oldPass" type="password" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item has-feedback label="New Password" prop="pass">
          <a-input v-model="passChange.pass" type="password" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item has-feedback label="Confirm New Password" prop="checkPass">
          <a-input v-model="passChange.checkPass" type="password" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 16, offset: 4 }">
          <a-button type="primary" @click="submitForm('passChange')">
            Submit
          </a-button>
          <a-button style="margin-left: 10px" @click="resetForm('passChange')">
            Reset
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import { message } from 'ant-design-vue';
import { mapGetters } from 'vuex';

export default {
  /* eslint-disable */
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'));
      } else if (this.errors.pass) {
        callback(new Error(this.errors.pass[0]));
        this.errors = {
          ...this.errors,
          pass: '',
        }
      } else {
        if (this.passChange.checkPass !== '') {
          this.$refs.passChange.validateField('checkPass');
        }
        callback();
      }
    };
    let validateOldPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the old password'));
      } else if (this.errors.oldPass){
        callback(new Error(this.errors.oldPass[0]));
        this.errors = {
          ...this.errors,
          oldPass: '',
        }
      } else {
        callback();
      }
    };
    let validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input your email'));
      } else if (this.errors.email){
        callback(new Error(this.errors.email[0]));
        this.errors = {
          ...this.errors,
          email: '',
        }
      } else {
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'));
      } else if (value !== this.passChange.pass) {
        callback(new Error("Two inputs don't match!"));
      } else if (this.errors.checkPass) {
        callback(new Error(this.errors.checkPass[0]));
        this.errors = {
          ...this.errors,
          checkPass: '',
        }
      } else {
        callback();
      }
    };
    return {
      errors: {},
      passChange: {
        email: '',
        pass: '',
        checkPass: '',
        oldPass: '',
      },
      rules: {
        email: [{ validator: validateEmail, trigger: 'change' }],
        oldPass: [{ validator: validateOldPass, trigger: 'change' }],
        pass: [{ validator: validatePass, trigger: 'change' }],
        checkPass: [{ validator: validatePass2, trigger: 'change' }],
      },
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 8 },
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'info/userInfo',
    }),
  },
  mounted() {
    this.passChange.email = this.userInfo && this.userInfo.email || '';
  },
  methods: {
    // async refreshToken() {
    //   window.refreshingToken = true;
    //   const res = await axios.get('/refresh');
    //   const now = moment();
    //   localStorage.setItem('token', res.headers.authorization);
    //   axios.defaults.headers = {
    //     Authorization: `Bearer ${res.headers.authorization}`,
    //   };
    //   localStorage.setItem('lastTokenRefresh', String(now.unix()));
    //   window.refreshingToken = false;
    // },
    async logout() {
      const success = await this.$store.dispatch('auth/logout', {
        logOutAllDevices: true,
      });
      if (success) {
        await this.$store.dispatch('info/clearUserInfo');
        await this.$router.push('/login');
      }
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            const res = await axios.post('admin/change-password', this.passChange );
            if (!res || !res.data) {
              message.error(
                'Password change failed'
              );
              return
            }
            if (res.data.errors) {
              this.errors = res.data.errors;
              this.$refs[formName].validateField(Object.keys(res.data.errors));
              message.error(
                'Password change failed'
              );
            } else if(res.data.success) {
              message.success(
                'Your password has been successfully changed'
              );
              await this.logout();
            }
          } catch (e) {
            message.error(
              'Password change failed'
            );
            return e;
          }
        } else {
          return false;
        }
      });
    },
    async resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss">
.pass-change {
  padding-top: 3%;
  background: white;
  min-height: 90%;
  margin: 24px;
  border-radius: 10px;
  .page-title{
    font-size: 20px;
    color: rgba(60, 57, 57, 0.85);
    font-weight: bold;
  }
}
</style>
